<template>
  <div>
    <v-row>
      <v-col cols="12" lg="4">
        <v-img src="/Bilder/coming-soon.png"
               width="100%"
               class="elevation-1"></v-img>
      </v-col>

      <v-col cols="12" lg="6" class="text-end">
        <h1 style="color:#cf160d;">Der Drache</h1>
        <h2>Ein satirisches Märchen von Jewgeni Schwarz</h2>
        <br><br>
        <p class="body-1">
          In einer Welt, in der ein Drache seit 400 Jahren über eine Stadt herrscht,
          scheint der Kampf gegen das Böse längst verloren. Doch als der tapfere Lancelot
          auftaucht, erwacht die Hoffnung auf Freiheit. Wird er es schaffen,
          den Drachen zu besiegen und die Stadt zu befreien?
          Oder wird die Tyrannei weitergehen – mit einem neuen Herrscher?
          <br><br>
          Dieses packende und zugleich tiefsinnige Stück von
          Jewgeni Schwarz begeistert mit schwarzem Humor, überraschenden Wendungen
          und einer zeitlosen Botschaft über Macht, Mut und Manipulation.
          <br><br>
          Erleben Sie ein modernes Märchen, das zum Nachdenken anregt und zugleich unterhält!
        </p>
      </v-col>
    </v-row>

    <!--
    <div>
      <v-row>
        <v-col cols="12" lg="6">
          <b style="color: #c00">Besetzung: </b><br><br>
          <table>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Gott</td>
              <td>Marina Müller</td>
            </tr>
            <tr>
              <td>Tod</td>
              <td>Nicki Fischer</td>
            </tr>
            <tr>
              <td>Tapir</td>
              <td>Thomas Loichinger</td>
            </tr>
            <tr>
              <td>Reh</td>
              <td>Doris v. Krusenstjern</td>
            </tr>
            <tr>
              <td>Helmut</td>
              <td>Jens Blum</td>
            </tr>
            <tr>
              <td>Helga</td>
              <td>Sousan Ohanwe</td>
            </tr>
            <tr>
              <td>Helge</td>
              <td>René Zarwel</td>
            </tr>
            <tr>
              <td>Helges Angst</td>
              <td>Yuko Thoma</td>
            </tr>
            <tr>
              <td>Tina</td>
              <td>Silke Jordan</td>
            </tr>
            <tr>
              <td>Tinas Angst</td>
              <td>Carola Johnson</td>
            </tr>
            <tr>
              <td>Chefin</td>
              <td>Hildegard Wahler</td>
            </tr>
            <tr>
              <td>Krankenschwester</td>
              <td>Jasmina Neudecker</td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" lg="6">
          <b style="color: #c00">Mitwirkende: </b><br><br>
          <table>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Regie</td>
              <td>Marget Flach</td>
            </tr>
            <tr>
              <td>Licht</td>
              <td>M. Hänel</td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </div>
    -->

    <br>
    <div>
      <b style="color: #c00">Vorstellungen:</b><br>

      <v-row>
        <v-col cols="12">
          <h5>Jugendkulturwerkstatt Soundcafé <br>(Traubestr. 5; am Ungererbad; U6
            Nordfriedhof)</h5>
          <v-row>
            <v-col cols="12" lg="6">
              <table>
                <tr>
                  <td>Datum</td>
                  <td>Uhrzeiten</td>
                </tr>
                <tr>
                  <td>Sa., 29.03.2025</td>
                  <td>19:30 Uhr</td>
                </tr>
                <tr>
                  <td>So., 30.03.2025</td>
                  <td>18:00 Uhr</td>
                </tr>
                <tr>
                  <td>Sa., 05.04.2025</td>
                  <td>19:30 Uhr</td>
                </tr>
                <tr>
                  <td>So., 06.04.2025</td>
                  <td>18:00 Uhr</td>
                </tr>
              </table>


              Infos zum VVK folgen in Kürze hier auf dieser Seite. <br>
              Wir freuen uns sehr auf Ihren Besuch!


              <!--
              <br>
              Karten für die Vorstellungen im Soundcafé können <br>
              über die folg. Telefonnummer reserviert:<br>
              <a href="tel:+498935846629"><b style="font-size: 18px; margin-left: 20px">089 35846629</b></a> <br>
              oder direkt hier über die Webseite: <br>
              <v-btn color="primary"
                     to="/Tickets"
                     style="color: white !important;">Kartenreservierung
              </v-btn>
              -->

              <!--
              Leider sind bereits alle Karten für das Soundcafé ausverkauft. Aber Sie können noch
              für andere Spielorte reservieren.
              -->


            </v-col>

            <v-col cols="12" lg="6">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2660.8101386254298!2d11.589970115761194!3d48.17174037922621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479e75b51e76f40b%3A0x3aa6c99beeb2329b!2ssoundcafe+(Kreisjugendring+M%C3%BCnchen-Stadt)!5e0!3m2!1sde!2sde!4v1456675718524"
                      width="100%" height="250" frameborder="0" style="border:0"
                      allowfullscreen></iframe>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!--
    <div class="mt-5">
      <b style="color: #c00">Downloads: </b><br>
      <v-btn class="mr-5"
             color="primary"
             style="color: white !important;"
             href="/Downloads/HelgesLeben_Flyer.pdf">Flyer
      </v-btn>
      <v-btn color="primary"
             style="color: white !important;"
             href="/Downloads/HelgesLeben_Plakat.pdf">Plakat
      </v-btn>
    </div>
    -->

    <div v-if="bilderAnzahlAuffuehrungen" style="margin-top: 3rem">
      <b style="color: #c00">Bilder von den Aufführungen:</b><br>
    </div>
    <v-row v-if="bilderAnzahlAuffuehrungen" class="pt-5">
      <v-col :key="n" v-for="n in bilderAnzahlAuffuehrungen" cols="12" lg="4">
        <v-img :src="'/Bilder/' + pathName + '/' + name + '-' + (n + bilderAnzahl) + '.' + bilderFormat"
               style="width:100%;cursor:pointer"
               @click="onClick(n + bilderAnzahl)"
               class="rounded-corners hover-opacity elevation-1">
        </v-img>
      </v-col>
    </v-row>
    <div v-if="bilderAnzahl" style="margin-top: 3rem">
      <b style="color: #c00">Bilder von den Proben:</b><br>
    </div>
    <v-row  v-if="bilderAnzahl" class="pt-5">
      <v-col :key="n" v-for="n in bilderAnzahl" cols="12" lg="4">
        <v-img :src="'/Bilder/' + pathName + '/' + name + '-' + n + '.' + bilderFormat"
               style="width:100%;cursor:pointer"
               @click="onClick(n)"
               class="rounded-corners hover-opacity elevation-1">
        </v-img>
      </v-col>
    </v-row>


    <!-- Modal Window for images -->
    <v-dialog v-model="imgDialog">
      <v-img @click="imgDialog=false" :src="imgDialogSrc" contain max-height="90vh"></v-img>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "Drache",
  metaInfo: {
    title: "Der Drache",
    meta: [
      {name: 'description', content: 'Details zu unserer Produktion: Der Drache'}
    ]
  },
  data() {
    return {
      name: "Drache",
      pathName: "Drache",
      bilderAnzahl: 0,
      bilderAnzahlAuffuehrungen: 0,
      bilderFormat: 'jpg',
      imgDialog: false,
      imgDialogSrc: ''
    }
  },
  methods: {
    onClick(index) {
      this.imgDialogSrc = '/Bilder/' + this.pathName + '/' + this.name + '-' + index + '.' + this.bilderFormat;
      this.imgDialog = true;
    }
  }
}
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  font-size: small;
  padding: 8px;
}

tr:first-child {
  background-color: #eee;
}
</style>
