<template>
  <v-row>
    <v-col cols="12" lg="5" class="text-end">
      <h3 class="font-italic font-weight-light">Vor allem aber müssen wir eines <br/>
        wollen: ... die Zuschauer verführen,<br/>
        auf dass sie nüchtern oder trunken <br/>
        werden, angeregt, aufgeregt und <br/>
        angestoßen, auch durch <br/>
        Anstößiges.</h3>
      <br/>
      <p class="body-1"> August Everding</p>
    </v-col>
    <v-col cols="12" lg="5" class="mb-10">
      <h3><b>Aktuell:</b></h3>

      <drache-card class="mt-10"></drache-card>
      <anwaerbung-card class="mt-10"></anwaerbung-card>
    </v-col>
  </v-row>
</template>

<script>
import DracheCard from "../components/wasist/DracheCard"
import AnwaerbungCard from "@/index/components/wasist/AnwaerbungsCard";

export default {
  name: "Start",
  components: {AnwaerbungCard, DracheCard},
  metaInfo: {
    title: "Start",
    meta: [
      {name: 'description', content: 'Was läuft bei uns aktuell'}
    ]
  }
}
</script>

<style scoped>

</style>