<template>
    <div>
        <v-row>
            <v-col cols="12" lg="5" class="text-end">
            <h3 class="font-italic font-weight-light">Mit einer leeren Bühne<br>
                    fängt es immer an.<br>
                    Dann füllt sich die Bühne<br>
                    mit Menschen,<br>
                    mit Leben,<br>
                    mit Geschichten.<br>
                    Und das Theater des Lebens<br>
                    beginnt von Neuem.<br></h3>
                <br/>
                <p class="body-1">Aus der "Witch"</p>
            </v-col>
            <v-col cols="12" lg="5">
                <div>
                  <h3><b>Aktuelle Stücke:</b></h3><br>
                  <drache-card class="mt-10"></drache-card>
                  <anwaerbung-card class="mt-10"></anwaerbung-card>
                </div>

                <br><br>
                <div style="display: inline-block; margin-top: 30px">
                    Selbstverständlich halten wir Sie auf dieser Seite auf dem Laufenden, <br>
                    mit mehr Einzelheiten und Terminen zu unseren Aufführungen.<br><br>
                    Herzlichst<br>
                    Ihre Theatergruppe Heidekraut
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DracheCard from "../components/wasist/DracheCard"
import AnwaerbungCard from "@/index/components/wasist/AnwaerbungsCard";

    export default {
        name: "wasIst",
        components: {AnwaerbungCard, DracheCard},
        metaInfo: {
            title: "Was ist",
            meta: [
                {name: 'description', content: 'Alle Stücke, die wir aktuell aufführen oder produzieren'}
            ]
        },
    }
</script>

<style scoped>
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td, th {
        border: 1px solid #dddddd;
        text-align: left;
        font-size: small;
        padding: 8px;
    }

    tr:first-child {
        background-color: #eee;
    }
</style>