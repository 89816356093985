<template>
    <div>
        <v-app-bar
                app
                clipped-left
                elevate-on-scroll
                color="secondary"
                v-scroll="onScroll"
                class="topline"
        >
            <v-app-bar-nav-icon class="hidden-lg-and-up"
                                @click.stop="drawerActive = !drawerActive"
            ></v-app-bar-nav-icon>
            <v-toolbar-title class="hidden-md-and-down">
                <div :class="title.color + ' headline'">{{title.big}} <u
                        >{{title.small}}</u></div>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <router-link to="/Start" class="mt-1">
                    <v-img src="/images/Heidekraut_Logo.png"
                           max-height="80"
                           max-width="230"
                           transition="fade-transition"
                           contain
                    ></v-img>
                </router-link>
            </v-toolbar-items>
        </v-app-bar>
        <v-navigation-drawer
                app
                clipped
                v-model="drawerActive"
        >
            <div>
                <v-list class="mt-5" dense>
                    <v-list-item>
                        <router-link to="/was_war" active-class="active">
                            <div class="dot_red"></div>
                            was <u>war</u>
                        </router-link>
                    </v-list-item>
                    <v-list-item>
                        <router-link to="/was_ist" active-class="active">
                            <div class="dot_blue"></div>
                            was <u>ist</u>
                        </router-link>
                    </v-list-item>
                    <v-list-item>
                        <router-link to="/was_wird" active-class="active">
                            <div class="dot_green"></div>
                            was <u>wird</u>
                        </router-link>
                    </v-list-item>
                </v-list>
                <v-list class="mt-5">
                    <v-list-item>
                        <router-link to="/was_ist" active-class="active">
                            Das Neueste
                        </router-link>
                    </v-list-item>
                    <v-list-item>
                        <router-link to="/Akteure" active-class="active">
                            Die Akteure
                        </router-link>
                    </v-list-item>
                    <v-list-item>
                        <router-link to="/Proben" active-class="active">
                            Die Proben
                        </router-link>
                    </v-list-item>
                    <v-list-item>
                        <router-link to="/Verein" active-class="active">
                            Der Verein
                        </router-link>
                    </v-list-item>
                    <v-list-item>
                        <router-link to="/Impressum" active-class="active">
                            Das Impressum
                        </router-link>
                    </v-list-item>
                </v-list>
                <v-list class="mt-5">
                    <v-list-item>
                        <router-link to="/Kontakt" active-class="active">
                            Kontakt
                        </router-link>
                    </v-list-item>
                    <!--
                    <v-list-item>
                        <router-link to="/Tickets" active-class="active">
                            Tickets reservieren
                        </router-link>
                    </v-list-item>
                    -->
                </v-list>
                <v-list class="mt-10">
                    <v-list-item class="mb-5">
                      <a href="https://www.facebook.com/TheatergruppeHeidekraut" target="_blank">
                        <img src="/images/facebook.png" height="50rem">
                      </a>
                    </v-list-item>
                    <v-list-item>
                      <a href="https://www.instagram.com/theatergruppeheidekraut/" target="_blank">
                        <img src="/images/instagram.png" height="50rem">
                      </a>
                    </v-list-item>
                </v-list>
            </div>
        </v-navigation-drawer>
    </div>
</template>

<script>
    export default {
        name: "AppMenu",
        data() {
            return {
                title: {
                    big: "",
                    small: "",
                    color: "header_grey"
                },
                titelMap: {
                    "was_war": {big: "Was", small: "war", color: 'header_red'},
                    "was_ist": {big: "Was", small: "ist", color: 'header_blue'},
                    "was_wird": {big: "Was", small: "wird", color: 'header_green'},
                    "Akteure": {big: "Die", small: "Akteure", color: 'header_grey'},
                    "Proben": {big: "Die", small: "Proben", color: 'header_grey'},
                    "Verein": {big: "Der", small: "Verein", color: 'header_grey'},
                    "Impressum": {big: "Das", small: "Impressum", color: 'header_grey'},
                    "Kontakt": {big: "Kontakt", small: "aufnehmen", color: 'header_grey'},
                    "Tickets": {big: "Tickets", small: "reservieren", color: 'header_grey'},
                    default: {big: "", small: "", color: ""}
                },
                drawerActive: null,
                lineActive: true,
            }
        },
        mounted() {
            this.setTitle();
        },
        watch: {
            $route() {
                this.setTitle();
            }
        },
        methods: {
            setTitle() {
                this.title = this.titelMap[this.$route.path.split('/')[1]] || this.titelMap.default
            },
            onScroll() {
                this.lineActive = !window.pageYOffset
            },
        }
    }
</script>
<style scoped>
    .v-list-item {
        min-height: 2rem;
    }
</style>

<style>
    .active {
        color: #cf160d !important
    }

    .v-navigation-drawer__border {
        background-color: white !important;
    }

    .v-toolbar__content {
        border-bottom: 1px solid #333333;
    }

    @media (min-width: 1200px) {
        .v-toolbar__content {
            margin-left: 5rem;
            margin-right: 5rem;
        }
    }

    @media (max-width: 1199px) {
        .v-toolbar__content {
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
</style>